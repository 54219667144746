import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Dashbot Taking Leading Role in GSMA Initiative to Advance Next Generation of Connectivity and Digital Services`}</h2>
    <p><strong parentName="p">{`5 September 2018, San Francisco`}</strong>{`: Dashbot today announced that it has been named one of the first companies in the “GSMA 100”, a global innovation discovery initiative developed by the GSMA to identify and advance the next generation of connectivity and digital services. Comprised of the world’s most promising growth-stage companies and selected by mobile operators and investment partners, the GSMA 100 represents operator innovation priorities in key areas such as 5G and networking; artificial intelligence and machine learning; consumer experience; data and analytics; the Internet of Things; media and entertainment; and security, among others.`}</p>
    <p>{`“Congratulations to Dashbot for their selection into the GSMA 100. The GSMA 100 is designed to identify those companies with the most compelling use cases and innovative business models,” said Ed Barker, Head of Innovation Strategy and Partnerships, GSMA. `}<em parentName="p">{`“We look forward to working with Dashbot and other GSMA 100 companies in accelerating business development, commercial partnerships and investment with mobile operator and key partners and, ultimately, in the deployment of next-generation networks and digital services that will benefit billions of subscribers around the world.”`}</em></p>
    <p>{`“We are excited and honored to be part of the GSMA 100, and look forward to working more closely with the GSMA and its partners,” said Arte Merritt, CEO and co-founder of Dashbot.`}</p>
    <p>{`The GSMA 100 are nominated by GSMA operator members, ecosystem companies and leading technology investors and primarily include growth-stage, venture-backed companies from their portfolios, partnerships or interest areas. Following a competitive review process, the nominations are evaluated and narrowed to yield the final cohorts. Dashbot was among the first 29 companies named to the GSMA 100; the remaining two cohorts for the GSMA 100 will made public in February 2019 at Mobile World Congress in Barcelona, and in June 2019 at Mobile World Congress Shanghai.`}</p>
    <p>{`The GSMA represents the interests of mobile operators worldwide, uniting more than 750 operators with over 350 companies in the broader mobile ecosystem. Key operators and partners contributing to the GSMA 100 include Amadeus Capital, América Móvil, AT`}{`&`}{`T Foundry, Bessemer Venture Partners, Blumberg Capital, BT, China Mobile, Deutsche Telekom, EIT Digital, Ericsson Ventures, Forgepoint Capital, GE Ventures, GV, IQT, KDDI, KPN Ventures, Lumia Venture Partners, Mayfield, MTN, Orange, Rogers Communications, Safaricom, Sony Innovation Fund, Telefónica, Telenor Group, Telstra Ventures, Telus, the UN World Food Programme, US Cellular, Verizon Ventures and Vodafone, among others.`}</p>
    <p>{`For further information on the GSMA 100, visit: `}<a parentName="p" {...{
        "href": "https://www.gsma.com/aboutus/gsma-100"
      }}>{`www.gsma.com/aboutus/gsma-100`}</a>{`.`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p">{`Dashbot is an analytics platform for chatbots and voice assistants that enables brands and developers to increase user acquisition, engagement, and monetization through actionable insights and tools. In addition to traditional metrics, Dashbot provides conversation specific analytics like phrase clustering, sentiment analysis, and response effectiveness.`}</em></p>
    <p><em parentName="p">{`Dashbot launched two years ago and has processed more than 48 billion messages. For more information, visit `}<a parentName="em" {...{
          "href": "https://www.dashbot.io"
        }}>{`www.dashbot.io`}</a>{`.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      